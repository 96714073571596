//export const APIURL = "http://localhost:5099/api/";

// New Test API URL
export const APIURL = "https://api.scal-test.infixsofttech.com/api/";


//Authentication services
export const AdminLogin = "Account/AdminLogin";
export const AzureLogin = "Account/AzureLogin";

//Dashboard services
export const GetAllEntries = "Dashboard/GetAllEntries";//s1
export const GetAdminDashboardDetail = "Dashboard/GetAdminDashboardDetail";//s1

//CorporateMemberships services
// export const GetAllCorporateMemberList =
//   process.env.REACT_APP_GetAllCorporateMemberList;
// export const GetCorporateMembersById =
//   process.env.REACT_APP_GetCorporateMembersById;
// export const DeleteCorporateMembers =
//   process.env.REACT_APP_DeleteCorporateMembers;
// export const AddEditCorporateMembers =
//   process.env.REACT_APP_AddEditCorporateMembers;
// export const AddEditOrganisationBasicInformation = "MembershipCorporate/AddEditOrganisationBasicInformation";
export const GetAllDropdownsForCorporateMember =
  "MembershipCorporate/GetAllDropdownsForCorporateMember";
export const AddEditCorporateSIISAccountSegment =
  "MembershipCorporate/AddEditCorporateSIISAccountInformation";
// export const GetCorporateBasicSIISInformation =
//   "MembershipCorporate/GetCorporateBasicSIISInformation?id={0}";
export const AddEditCorporateEngagementTracking =
  "CorporateMember/AddEditCorporateEngagementTracking";
//export const GetCorporateEngagementTrackingList = "CorporateMember/GetCorporateEngagementTrackingList?id={0}";
export const GetCorporateEngagementTrackingList =
  "MembershipCorporate/GetEngagementTrackingListById?id={0}";
//export const GetCorporateEngagementDetailsById = "MembershipCorporate/GetCorporateEngagementDetailsById?id={0}";
export const GetCorporateEngagementDetailsById =
  "MembershipCorporate/GetEngagementTrackingById?id={0}";

export const AddEditCorporateChapter =
  "MembershipCorporate/AddEditCorporateChapter";
// export const GetCorporateChapter =
//   "MembershipCorporate/GetCorporateChapter?id={0}";
export const GetEmployeeInfomationForCorporateMemberAdmin =
  "MembershipCorporate/GetEmployeeInfomationForCorporateMemberAdmin?id={0}&nameSearch={1}";
export const GetAllCorporateOrganisationRole =
  "CorporateMember/GetAllCorporateOrganisationRole?id={0}";
export const GetChapterCommitteeRoles =
  "CorporateMember/GetChapterCommitteeRoles?id={0}";
export const AddEditEmployeeInformation =
  "MembershipCorporate/AddEditEmployeeInformation";
export const GetEmployeeInformationById =
  "MembershipCorporate/GetEmployeeInformationById?id={0}";
export const AddEditCorporateOrganisationRole =
  "CorporateMember/AddEditCorporateOrganisationRole";
export const DeleteCorporateOrganisationRole =
  "CorporateMember/DeleteCorporateOrganisationRole";
export const GetCorporateOrganisationRoleById =
  "CorporateMember/GetCorporateOrganisationRoleById?id={0}";
//New End Points for create corporate members
export const AddEditSIISCorporateAccountInformation =
  "MembershipCorporate/AddEditSIISCorporateAccountInformation";
export const AddEditEngagementTrackingList =
  "MembershipCorporate/AddEditEngagementTrackingList";
export const AddEditProspectInformation =
  "MembershipCorporate/AddEditProspectInformation";
// export const AddEditCorporateProspect =
//   "MembershipCorporate/AddEditCorporateProspect";
export const AddEditFinanceCardDetails =
  "MembershipCorporate/AddEditFinanceCardDetails";
export const AddEditGovernmentPrograme =
  "MembershipCorporate/AddEditGovernmentPrograme";
export const AddEditCorporateMembershipSubscription =
  "MembershipCorporate/AddEditCorporateMembershipSubscription";
export const AddEditChapterInformation =
  "MembershipCorporate/AddEditChapterInformation";
export const AddEditOrganisationBasicInformation =
  "MembershipCorporate/AddEditOrganisationBasicInformation";
export const AddEditOrganisationBasicInformationAdmin =
  "MembershipCorporate/AddEditOrganisationBasicInformation_admin";
export const AddEditOrganisationProfile =
  "MembershipCorporate/AddEditOrganisationProfile";
export const AddEditOrganisationBussinessDetails =
  "MembershipCorporate/AddEditOrganisationBussinessDetails";
export const AddEditSGTechIntrest = "MembershipCorporate/AddEditTACIntrest";
export const AddEditOrganisationKeyRoles =
  "MembershipCorporate/AddEditOrganisationKeyRoles";
export const JoinChapterById = "MembershipCorporate/JoinChapterById";
export const DeleteChapterById = "MembershipCorporate/DeleteChapterById";
export const AddEditCorporateCommunication =
  "MembershipCorporate/AddEditCorporateCommunication";
export const AddEditOrganisationEmployees =
  "MembershipCorporate/AddEditOrganisationEmployees";
export const AddEditFinancialDetail =
  "MembershipCorporate/AddEditFinancialDetail";
export const AddEditEvenBookingData =
  "MembershipCorporate/AddEditEvenBookingData";
export const GetAllCorporateSectionsById =
  "MembershipCorporate/GetAllCorporateSectionsById?id={0}";
export const GetSIISCorporateAccountInformationById =
  "MembershipCorporate/GetSIISCorporateAccountInformationById?id={0}";
export const GetEngagementTrackingListById =
  "MembershipCorporate/GetEngagementTrackingListById?id={0}";
export const GetFinanceCardDetailsById =
  "MembershipCorporate/GetFinanceCardDetailsById?id={0}";
export const GetGovernmentProgrameById =
  "MembershipCorporate/GetGovernmentProgrameById?id={0}";
export const GetCorporateMembershipSubscriptionById =
  "MembershipCorporate/GetCorporateMembershipSubscriptionById?id={0}";
export const GetProspectInformationById =
  "MembershipCorporate/GetProspectInformationById?id={0}";
export const GetChapterInformationById =
  "MembershipCorporate/GetChapterInformationById?id={0}";
export const GetOrganisationBasicInformationById =
  "MembershipCorporate/GetOrganisationBasicInformationById?id={0}";
export const GetOrganisationProfileById =
  "MembershipCorporate/GetOrganisationProfileById?id={0}";
export const GetOrganisationBussinessDetailsById =
  "MembershipCorporate/GetOrganisationBussinessDetailsById?id={0}";
export const GetSGTechIntrestById =
  "MembershipCorporate/GetTACIntrestById?id={0}";
export const GetOrganisationKeyRolesById =
  "MembershipCorporate/GetOrganisationKeyRolesById?id={0}";
export const GetWorkgroupDataById =
  "MembershipCorporate/GetWorkgroupDataById?id={0}";
export const GetCommunicationById =
  "MembershipCorporate/GetCommunicationById?id={0}";
export const GetOrganisationEmployeesById =
  "MembershipCorporate/GetOrganisationEmployeesById?id={0}";
export const GetFinancialDetailById =
  "MembershipCorporate/GetFinancialDetailById?id={0}";
export const GetEvenBookingDataById =
  "MembershipCorporate/GetEvenBookingDataById?id={0}";

//Privileges Services
// export const AddEditPrivileges = process.env.REACT_APP_AddEditPrivileges;
// export const GetPrivilegesList = process.env.REACT_APP_GetPrivilegesList;
// export const GetPrivilegesById = process.env.REACT_APP_GetPrivilegesById;
// export const DeletePrivileges = process.env.REACT_APP_DeletePrivileges;

//Common Services
// export const GetAllAreaOfExpertise =
//   process.env.REACT_APP_GetAllAreaOfExpertise;
// export const GetAllBusinessSectors =
//   process.env.REACT_APP_GetAllBusinessSectors;
// export const GetAllChapters = process.env.REACT_APP_GetAllChapters;
// export const GetAllCountries = process.env.REACT_APP_GetAllCountries;
// export const GetAllStatus = process.env.REACT_APP_GetAllStatus;
export const StoreBase64Image = process.env.REACT_APP_StoreBase64Image;
export const GetImage = "Common/GetImage";

//CommunitySpotlight Services
//export const AddEditCommunitySpotlight = process.env.REACT_APP_AddEditCommunitySpotlight;
//export const GetCommunitySpotlight = process.env.REACT_APP_GetCommunitySpotlight;

//Resource Services
//export const GetAllResourcesList = process.env.REACT_APP_GetAllResourcesList;
//export const GetResourceById = process.env.REACT_APP_GetResourceById;
//export const DeleteResources = process.env.REACT_APP_DeleteResources;
//export const AddEditResources = process.env.REACT_APP_AddEditResources;
//export const GetAllResourceTypes = process.env.REACT_APP_GetAllResourceTypes;
//export const GetAllResourceTopics = process.env.REACT_APP_GetAllResourceTopics;
//export const AddEditResourceTaggingDetails = process.env.REACT_APP_AddEditResourceTaggingDetails;
//export const GetResourceTaggingDetails = process.env.REACT_APP_GetResourceTaggingDetails;

//Banner Services
// export const AddEditBanners = process.env.REACT_APP_AddEditBanners;
// export const GetBanners = process.env.REACT_APP_GetBanners;
// export const AddEditBanners = "Banners/AddEditBanners"; // Banners
// export const GetBanners = "Banners/GetBanners?id={0}"; // Banners
// export const ClearAllBannersByPositionId = // Banners
//   "Banners/ClearAllBannersByPositionId?id={0}";

// Membership Campaign Services
// export const GetMembershipCampaignList =
//   process.env.REACT_APP_GetMembershipCampaignList;
// export const ActiveInActiveCampaign = "MembershipCampaign/ActiveInActiveMembershipCampaignStatusById";
// export const AddEditMembershipCampaign = "MembershipCampaign/AddEditMembershipCampaign";
// export const GetMembershipCampaignById = "MembershipCampaign/GetMembershipCampaignById?id={0}";
// export const DeleteMembershipCampaign = "MembershipCampaign/DeleteMembershipCampaign";

// // Membership Pricing Services
// export const AddMembershipPricing =
//   "MembershipPricing/AddEditMembershipPricing";
// export const GetMembershipPricing = "MembershipPricing/GetMembershipPricing";

//Pages Management Services
// export const GetPagesMainModulesList =
//   "PagesManagement/GetPagesMainModulesList";
// export const GetPagesDetailsList = "PagesManagement/GetPagesDetailsList";
// export const GetAllDropdownsForViewPages =
//   "PagesManagement/GetAllDropdownsForViewPages";
// export const GetPageDetailByIds =
//   "PagesManagement/GetPageDetailByIds?MainMenuId={0}&SubMenuId={1}&PageNameId={2}";
// export const GetSubModulesListByMainModuleId =
//   "PagesManagement/GetPagesSubModulesListById?id={0}";
// export const SavePageDetails = "PagesManagement/AddEditPageDetail";
// export const UpdatePageDetails = "PagesManagement/UpdatePageDetails";
// export const GetAllDropdownsForPages =
//   "PagesManagement/GetAllDropdownsForPages";
// export const AddEditPartialPage = "PagesManagement/AddEditPartialPage";
// export const GetPartialPageComponentsById =
//   "PagesManagement/GetPartialPageComponentsById?id={0}";
// export const GetSubPagesListForSubModuleById =
//   "PagesManagement/GetSubPagesListForSubModuleById?id={0}";
// export const AddPageNameForSubModule =
//   "PagesManagement/AddPageNameForSubModule";
// export const DeletePageNamesById = "PagesManagement/DeletePageNamesById";
// export const GetPageDetailByPagesDetailsId =
//   "PagesManagement/GetPageDetailByPagesDetailsId?id={0}";
// export const DeleteStandAlonePageById =
//   "PagesManagement/DeleteStandAlonePageById?id={0}";

//Notification Announcement Services
// export const GetNotifiicationsList ="NotificationAnnouncement/GetNotifiicationsList";
// export const AddEditNotification = "NotificationAnnouncement/AddEditNotification";
// export const GetNotificationsById = "NotificationAnnouncement/GetNotificationsById?id={0}";
// export const DeleteNotification = "NotificationAnnouncement/DeleteNotification";

//Authority Limit Services // remove
//export const GetAuthorityLimitList = "AuthorityLimit/GetAuthorityLimitList";  // remove
//export const GetAllMembershipType = "AuthorityLimit/GetAllMembershipType"; // remove
//export const GetAllMembershipRevenue = "AuthorityLimit/GetAllMembershipRevenue"; // remove
//export const GetAllMembershipCategory =  "AuthorityLimit/GetAllMembershipCategory"; // remove
//export const GetAllMembershipFeeType = "AuthorityLimit/GetAllMembershipFeeType"; // remove
//export const AddEditAuthorityLimit = "AuthorityLimit/AddEditAuthorityLimit"; // remove
//export const GetAllMembershipTenure = "AuthorityLimit/GetAllMembershipTenure"; // remove
//export const GetAuthorityLimitById =  "AuthorityLimit/GetAuthorityLimitById?id={0}"; // remove
//export const DeleteAuthorityLimit = "AuthorityLimit/DeleteAuthorityLimit"; // remove

// export const GetAllMembershipTenure = "AuthorityLimit/GetAllMembershipTenure";

//Membership Task List Services
//export const GetAllTasksList = "MembershipTask/GetAllTasksList";
export const AddEditMembershipTask = "MembershipTask/AddEditMembershipTask";
//export const GetTaskByCompanyId = "MembershipTask/GetTaskByCompanyId?id={0}";
export const GetAllSources = "MembershipTask/GetAllSources";
//export const GetAllMembershipTypes = "AuthorityLimit/GetAllMembershipType"; // remove
export const GetAllTransactionaTypes = "MembershipTask/GetAllTransactionaTypes";
// export const GetPriceTabelForTaskListProcess = "MembershipTask/GetPriceTabelForTaskListProcess";
// export const GetAllBillingCategories =
//   "CorporateMember/GetAllCorporateMemberBillingCategory";
// export const GetAllCorporateProspectSource =
//   "CorporateMember/GetAllCorporateProspectSource";
export const GetAllDropdownsForMembershipTaskList =
  "MembershipTask/GetAllDropdownsForMembershipTaskList";
export const GetPriceTabelByCorporateMemberId =
  "MembershipTask/GetPriceTabelByCorporateMemberId?id={0}";
export const GetCalculatedFinalPrice = "MembershipTask/GetCalculatedFinalPrice";
// export const AddEditMembershipTaskAndSendPaymentLink =
//   "MembershipTask/AddEditMembershipTaskAndSendPaymentLink";
export const GetExistingDiscountTableByTaskId =
  "MembershipTask/GetExistingDiscountTableByTaskId?id={0}";
export const SendInvoiceEmailToCorporateMember =
  "MembershipTask/SendInvoiceEmailToCorporateMember";
// export const SendQuotationEmailToCorporateMember =
//   "MembershipTask/SendQuotationEmailToCorporateMember";
// export const GetAllPriceTables = "MembershipTask/GetAllPriceTables";
export const AddEditMembershipTaskAndSendForApproval =
  "MembershipTask/AddEditMembershipTaskAndSendForApproval";

// export const SendEscalateMailToMember =
//   "MembershipTask/SendEscalateMailToMember";
export const DownloadCertificateForStudentMemberById ="StudentMember/DownloadCertificateForStudentMemberById?id={0}";

export const DownloadCertificateForStudentMemberByPath ="StudentMember/DownloadCertificateForStudentMemberByPath?id={0}";

export const DownloadReceiptForStudentMemberById ="StudentMember/DownloadReceiptForStudentMemberById?id={0}";

export const DownloadInvoiceForStudentMemberById ="StudentMember/DownloadInvoiceForStudentMemberById?id={0}";
// Chapter X Committee Workgroup Services

//export const AddEditExcoMember = "CommitteeWorkGroup/AddEditExcoMembers";
//export const GetAllExcoPosition = "CommitteeWorkGroup/GetAllExcoPosition";
//export const GetCommitteeWorkGroupList = "CommitteeWorkGroup/GetAllCommitteeWorkGroupList";
//export const GetAllOrganisationName = "IndividualMember/GetAllOrganisationName";
// export const GetExcoMemberList = "CommitteeWorkGroup/GetExcoMemberList?id={0}";
//export const GetExcoMemberList = "CommitteeWorkGroup/GetExcoMemberList";
//export const GetExcoMemberById = "CommitteeWorkGroup/GetExcoMemberById?id={0}";
//export const GetAllDropdownForChapterXCommittee = "CommitteeWorkGroup/GetAllDropdownForChapterXCommittee";
//export const AddEditChapterDetails = "CommitteeWorkGroup/AddEditChapterDetails";
//export const DeleteCommitteeWorkgroupMapping = "CommitteeWorkGroup/DeleteCommitteeWorkgroupMapping";
//export const DeleteCommittees = "CommitteeWorkGroup/DeleteCommittees";
//export const SearchExcoMemberByEmail = "CommitteeWorkGroup/SearchExcoMemberByEmail";
//export const DeleteEXCOMembers = "CommitteeWorkGroup/DeleteEXCOMembers";
//export const DeleteChapters = "CommitteeWorkGroup/DeleteChapters";

//Membership Fee Approval

// export const GetAllPendingApprovalTasks = "MembershipFeeApproval/GetAllPendingApprovalTasks";
// export const GetPriceTablesForMembershipFeeApproval = "MembershipFeeApproval/GetPriceTablesForMembershipFeeApproval?id={0}";
// export const UpdatePriceTablesForMembershipFeeApproval = "MembershipFeeApproval/UpdatePriceTablesForMembershipFeeApproval";

// Individual Member
// export const GetAllDropdownsForIndividualMember =
//   "IndividualMember/GetAllDropdownsForIndividualMember";


//export const GetIndividualEngagementTrackingList =
//"IndividualMember/GetIndividualEngagementTrackingList?id={0}";
//export const AddEditEngagementTracking =
//"IndividualMember/AddEditEngagementTracking";
export const GetIndividualEngagementTrackingById =
  "IndividualMember/GetIndividualEngagementTrackingById?id={0}";
export const AddEditIndividualBasicInformation =
  "IndividualMember/AddEditIndividualBasicInformation";
//export const AddEditIndividualSIISInformation =
//"//IndividualMember/AddEditIndividualSIISInformation";
// export const GetIndividualBasicInformation =
//   "IndividualMember/GetIndividualBasicInformation?id={0}";
//export const GetIndividualSIISInformation =
//"IndividualMember/GetIndividualSIISInformation?id={0}";
export const AddEditIndividualCommittee =
  "IndividualMember/AddEditIndividualCommittee";
// export const GetIndividualCommittee =
//   "IndividualMember/GetIndividualCommittee?id={0}";
// export const AddEditIndividualMemberSubscription =
//   "IndividualMember/AddEditIndividualMemberSubscription";
// export const GetIndividualMemberSubscription =
//   "IndividualMember/GetIndividualMemberSubscription?id={0}";
// export const GetIndividualContributionList =
//   "IndividualMember/GetIndividualContributionList?id={0}";
// export const AddEditIndividualContribution =
//   "IndividualMember/AddEditIndividualContribution";
export const GetIndividualMemberList =
  "IndividualMember/GetIndividualMemberList";
// export const ActiveInActiveIndividualMember =
//   "IndividualMember/ActiveInActiveIndividualMember";
export const DeleteIndividualMember = "IndividualMember/DeleteIndividualMember";
// export const DeleteIndividualContribution =
//   "IndividualMember/DeleteIndividualContribution";


// Membership Corporate Services
export const GetMembershipCorporateList =
  "CorporateMember/GetMembershipCorporateList";
// export const ActiveInActiveMembershipCorporate =
//   "CorporateMember/ActiveInActiveMembershipCorporate";
export const DeleteMembershipCorporate =
  "CorporateMember/DeleteMembershipCorporate";
// export const GetChapterListByCorporateId =
//   "IndividualMember/GetChapterListForIndividualDashboard?id={0}";
export const GetGovernmentProgrammeDetailByProjectId =
  "MembershipCorporate/GetGovernmentProgrammeDetailByProjectId?id={0}";
export const GetCorporateFinancialInfo =
  "MembershipCorporate/GetCorporateFinancialInfo?id={0}";
export const GetCorporateMemberFiles =
  "MembershipCorporate/GetCorporateMemberFiles?id={0}&id2={1}";

  export const GetCorporateMemberFilesDownload =
  "MembershipCorporate/GetCorporateMemberFilesDownload?id={0}&id2={1}";

export const GetCorporateFinanceReceiptInvoice = "MembershipCorporate/GetCorporateFinanceReceiptInvoice?id={0}&id2={1}&id3={2}";
// export const RenewCorporateMember =
//   "WebMembershipCorporate/RenewCorporateMember";
export const GetDropdownDataOfWorkheadAndGrade =
  "CorporateMember/GetDropdownDataOfWorkheadAndGrade";

// export const AddEditCorporateSIISAccountSegment = "MembershipCorporate/AddEditCorporateSIISAccountSegment";
// export const GetCorporateBasicSIISInformation = "MembershipCorporate/GetCorporateBasicSIISInformation";

export const GetAllCorporateStatus =
  "MembershipCorporate/GetAllCorporateStatus";

// New API end points
// export const GetAllIndividualProfileDetail =
//   "IndividualMember/GetAllIndividualProfileDetail?id={0}";
// export const GetEmploymentInformation =
//   "IndividualMember/GetEmploymentInformation?id={0}";
// export const AddEditEmploymentInformation =
//   "IndividualMember/AddEditEmploymentInformation";
// export const GetEmailHistoryForIndividualMemberById =
//   "IndividualMember/GetEmailHistoryForIndividualMemberById?id={0}";
export const GetBookingListForIndividualMemberById =
  "IndividualMember/GetBookingListForIndividualMemberById";

// Field Management
export const GetAllTagTypeDropdown = "FieldManagement/GetAllTagTypeDropdown";
export const GetAllTagList = "FieldManagement/GetAllTagList";
export const DeleteTags = "FieldManagement/DeleteTags";
export const GetTagDetailById = "FieldManagement/GetTagDetailById?id={0}";
export const AddEditTag = "FieldManagement/AddEditTag";

export const AddEditTagManage = "TagManage/AddEditTagManage";
export const GetAllTagManageTypeDropdown = "TagManage/GetAllTagManageTypeDropdown";
export const GetAllTagManageList = "TagManage/GetAllTagManageList";
export const DeleteTagsManage = "TagManage/DeleteTagsManage";
export const GetTagManageDetailById = "TagManage/GetTagManageDetailById?id={0}";


export const GetAllProjectTypeDropdown =
  "FieldManagement/GetAllProjectTypeDropdown";
export const GetEmailTemplateList = "FieldManagement/GetEmailTemplateList";
export const DeleteEmailTemplate = "FieldManagement/DeleteEmailTemplate";
export const GetEmailTemplateById =
  "FieldManagement/GetEmailTemplateById?id={0}";
export const AddEditEmailTemplate = "FieldManagement/AddEditEmailTemplate";

export const GetDropdownRecordList = "FieldManagement/GetDropdownRecordList";
export const GetDropdownDatabyId = "FieldManagement/GetDropdownDatabyId?id={0}";
export const DeleteDropdownData = "FieldManagement/Deletedropdowndata";
export const AddEditDropdownData = "FieldManagement/AddEditDropdownData";
export const GetTableDataById =
  "FieldManagement/GetTableDataById?DropdownId={0}&TableDataId={1}";

// Admin scal
export const AddEditAdminDetails = "Account/AddEditAdminDetails"; // scal
export const GetRolesForAdmin = "Account/GetRolesForAdmin"; // scal
export const GetAdminDetailList = "Account/GetAdminDetailList"; // scal
export const GetAdminDetailById = "Account/GetAdminDetailById?id={0}"; // scal
export const DeleteAdminDetailsById = "Account/DeleteAdminDetailsById?id={0}"; // scal
export const ForgotPassword = "Account/ForgotPassword"; // remove
export const ResetPassword = "Account/ResetPassword";  // remove

//Email Management
// export const GetEmailHistoryList = "EmailManagement/GetEmailHistoryList";
// export const DeleteEmailDetailsById = "EmailManagement/DeleteEmailDetailsById?id={0}";

// export const GetAllRecipientsDropdown = "EmailManagement/GetAllRecipientsDropdown";
// export const GetEmailById = "EmailManagement/GetEmailById?id={0}";
// export const AddEditEmail = "EmailManagement/AddEditEmail";

// Finance Management
export const getReceiptAndCertificate = "MembershipCorporate/GetReceiptAndCertificate?id={0}";
export const GetFinanceGSTSettingList = "Finance/GetFinanceGSTSettingList";
export const GetFinanceClassCodeSettingList =
  "Finance/GetFinanceClassCodeSettingList";
export const GetFinanceAccountCodeSettingList =
  "Finance/GetFinanceAccountCodeSettingList";
export const GetFinanceCreditNoteApprovalList =
  "Finance/GetFinanceCreditNoteApprovalList";
export const AddEditFinanceGST = "Finance/AddEditFinanceGST";
export const AddEditFinanceClassCode = "Finance/AddEditFinanceClassCode";
export const AddEditFinanceAccountCode = "Finance/AddEditFinanceAccountCode";
export const AddEditFinanceCreditNoteApproval = "Finance/AddEditFinanceCreditNoteApproval";

//export const AddFinanceCreditNote = "Finance/AddFinanceCreditNote";
export const DeleteFinanceGlobal = "Finance/DeleteFinanceGlobal";

//bookings
//export const GetbookingsList = "Booking/GetbookingsList";
//export const GetBookingCancellationList = "Booking/GetBookingCancellationList";
//export const GetBookingDetailsById = "Booking/GetBookingDetailsById?id={0}";
//export const GetBookingCancellationDetailsById ="Booking/GetBookingCancellationDetailsById?id={0}";
//export const GetBookingDetailsByBookingId ="Booking/GetBookingDetailsByBookingId?attendeeId={0}&bookingId={1}";
//export const GetInvoiceReceiptPdfByBookingId ="IndividualMember/GetInvoiceReceiptPdfByBookingId?id={0}&type={1}";
// export const GetEventPaymentReconcilationList =
//   "Booking/GetEventPaymentReconcilationList";
// export const SavePaymentReconcilationDetailsByAdmin =
//   "Booking/SavePaymentReconcilationDetailsByAdmin";
// export const AcceptTotalPaidAmountInReconcilation =
//   "Booking/AcceptTotalPaidAmountInReconcilation?id={0}";
// export const AcceptTotalMembershipPaidAmountInReconciliation =
//   "MembershipPayment/AcceptTotalMembershipPaidAmountInReconciliation";
//export const AcceptTotalExternalInvoicePaidAmountInReconciliation = "Booking/AcceptTotalExternalInvoicePaidAmountInReconciliation?id={0}";

//export const AcceptTotalPurchaseChapterPaidAmountInReconciliation = "MembershipPayment/AcceptTotalPurchaseChapterPaidAmountInReconciliation?id={0}";


// Adhok Invoice In Finance
// export const AddEditFinanceAdhokInvoiceDetail =
//   "Finance/AddEditFinanceAdhokInvoiceDetail";
// export const GetAllDropdownsForFinanceAdhokInvoices =
//   "Finance/GetAllDropdownsForFinanceAdhokInvoices";
//export const GetFinanceAdhocInvoiceList = "Finance/GetFinanceAdhocInvoiceList";
// export const GetFinanceAdhokInvoiceDetail =
//   "Finance/GetFinanceAdhokInvoiceDetail?id={0}";
// export const AddEditFinanceExternalServiceDetail =
//   "Finance/AddEditFinanceExternalServiceDetail";
// export const GetFinanceExternalServiceDetailById =
//   "Finance/GetFinanceExternalServiceDetailById?id={0}";
// export const AddDrawdownForExternalServiceItem =
//   "Finance/AddDrawdownForExternalServiceItem";
// export const GetFinanceCreditNoteById =
//   "Finance/GetFinanceCreditNoteById?id={0}";

// Voucher Management
// export const GetAllVoucherCodeList = "Finance/GetAllVoucherCodeList";
// export const GetAllFinanceCreditNoteList =
//   "Finance/GetAllFinanceCreditNoteList";
// export const GetFinanceCreditNoteStatusList =
//   "Finance/GetFinanceCreditNoteStatusList";
// export const FinanceApproveRejectCreditNoteApproval =
//   "Finance/FinanceApproveRejectCreditNoteApproval";
// export const GetVoucherCodeDetailsById =
//   "Finance/GetVoucherCodeDetailsById?id={0}";
//export const ActiveInActiveVoucherCode = "Finance/ActiveInActiveVoucherCode";
//export const GetAllMemberListForVoucher = "Finance/GetAllMemberListForVoucher";
// export const AddEditVoucherCodeDetails = "Finance/AddEditVoucherCodeDetails";
export const GetAllChapterListForVoucher =
  "Finance/GetAllChapterListForVoucher";

// export const FinanceUpdateStatusForCreditNote =
//   "Finance/FinanceUpdateStatusForCreditNote";
// export const FinanceGetAllPendingEventApprovalList =
//   "Finance/FinanceGetAllPendingEventApprovalList";
// export const financeEventInvoiceApproval =
//   "Finance/financeEventInvoiceApproval";
export const FinanceGetInvoice = "Finance/FinanceGetInvoice?path={0}";
export const FinanceGetCreditNoteInvoice =
  "Finance/FinanceGetCreditNoteInvoice?id={0}";
// export const UpdatePaymentHistoryForMembershipPayment =
//   "MembershipPayment/UpdatePaymentHistoryForMembershipPayment?id={0}";
// export const UpdatePaymentHistoryForAdditionalChapter =
//   "MembershipPayment/UpdatePaymentHistoryForAdditionalChapter?id={0}";f
//export const UpdatePaymentHistory = "Booking/UpdatePaymentHistory?referenceNo={0}&eventVisitorId={1}";
export const GetAllExtractInvoicesList = "Finance/GetAllExtractInvoicesList";
//export const GetGSTRate = "Common/GetGSTRate";
export const ZipInvoices = "Finance/ZipInvoices";
export const GetTransactionRecordList = "Finance/GetTransactionRecordList";
export const DownloadSheetForTransactionRecord = "Finance/DownloadSheetForTransactionRecord";

// export const FinanceAddCreditNotePaymentProofMapping =
//   "Finance/FinanceAddCreditNotePaymentProofMapping";
//export const AddCreditNoteInvoiceRecordsForBatchJob = "BatchJob/AddCreditNoteInvoiceRecordsForBatchJob?id={0}";
//export const AddMembershipCreditNoteApprovalRecordsForBatchJob = "BatchJob/AddMembershipCreditNoteApprovalRecordsForBatchJob?id={0}";
//export const AddEventCreditNoteApprovalRecordsForBatchJob = "BatchJob/AddEventCreditNoteApprovalRecordsForBatchJob?id={0}";
//xport const AddContinuosPeriodExtInvCNApprovalXeroRecords = "BatchJob/AddContinuosPeriodExtInvCNApprovalXeroRecords?id={0}";

export const GetAllWeeklyBatchJobRecords = "BatchJob/GetAllWeeklyBatchJobRecords";
export const GetAllMonthlyBatchJobRecords = "BatchJob/GetAllMonthlyBatchJobRecords";
export const GenerateWeeklyBatchJob = "BatchJob/GenerateWeeklyBatchJob?id={0}";
export const GenerateMonthlyBatchJob = "BatchJob/GenerateMonthlyBatchJob?id={0}";
export const DownloadSheetByBatchJobId = "BatchJob/DownloadSheetByBatchJobId?id={0}";

// GST Reports
export const GetAllGSTReportList = "BatchJob/GetAllGSTReportList";
export const GenerateGSTReport = "BatchJob/GenerateGSTReport";
export const DownloadGSTSheetByGSTReportId = "BatchJob/DownloadGSTSheetByGSTReportId?id={0}";

//export const GetAllChartsOfAccountCodeList = "BatchJob/GetAllChartsOfAccountCodeList";
//export const GetAllDropdownsForBatchJob = "BatchJob/GetAllDropdownsForBatchJob";
//export const AddEditChartsOfAccountCode = "BatchJob/AddEditChartsOfAccountCode";



//////----[  Scal ]----//////

// Task List
export const GetTaskByCompanyId = "MembershipTask/GetTaskByCompanyId?id={0}";
export const GetTaskByIndividualMemberId = "MembershipTask/GetTaskByIndividualMemberId?id={0}";

// Membership Pricing Services
export const AddMembershipPricing = "MembershipPricing/AddEditMembershipPricing";
export const GetMembershipPricing = "MembershipPricing/GetMembershipPricing";
export const AddEditMembershipSlotSPricing = "MembershipPricing/AddEditMembershipSLOTSPricing";
export const GetMembershipSlotSPricing = "MembershipPricing/GetMembershipSLOTSPricing";
export const GetOutstandingInvoicesList = "MembershipPricing/GetOutstandingInvoicesList";
export const AddProofInPaymentProofMapping = "MembershipPricing/AddProofInPaymentProofMapping";
export const AddEditMembershipCONQUASPricing = "MembershipPricing/AddEditMembershipCONQUASPricing";
export const GetMembershipCONQUASPricing = "MembershipPricing/GetMembershipCONQUASPricing";
export const GetCommentsInvoicesList = "MembershipPricing/GetCommentsInvoicesList";
export const addEditStudentMembershipPricing = "StudentMember/addEditStudentMembershipPricing";
export const getStudentMembershipPricing = "StudentMember/GetStudentMembershipPricing";
export const acceptAndApprovedStudentApplication = "StudentMember/AcceptAndApprovedStudentApplication";
//Membership Task List Services 
export const GetAllTasksList = "MembershipTask/GetAllTasksList";
export const GetCorporateMemberPricing = "CorporateMember/GetCorporateMemberPricing";
export const GetAllIndividualMemberTaskList = "MembershipTask/GetAllIndividualMemberTaskList";
export const getClarificationDetails = "CorporateMember/SaveClarificationDetails?id={0}";
export const getAllStudentMemberTaskList = "StudentMember/GetAllStudentTasksList";
//export const GetCorporatePriceTabelForTaskListProcess = "MembershipTask/GetCorporatePriceTabelForTaskListProcess?id={0}"

// Reconcil

export const GetEventPaymentReconcilationList =
  "MembershipPricing/GetEventPaymentReconcilationList";
export const SavePaymentReconcilationDetailsByAdmin =
  "MembershipPricing/SavePaymentReconcilationDetailsByAdmin";
export const AcceptTotalPaidAmountInReconcilation =
  "MembershipPricing/AcceptTotalPaidAmountInReconcilation?id={0}";
export const AcceptTotalMembershipPaidAmountInReconciliation =
  "MembershipPricing/AcceptTotalMembershipPaidAmountInReconciliation";
export const AddEditMembershipTaskAndSendPaymentLink =
  "MembershipTask/AddEditMembershipTaskAndSendPaymentLink";
//export const GetCompanyRegisteredWithBCAList ="CorporateMember/GetCompanyRegisteredWithBCAList?id={0}";
export const GetCompanyRegisteredWithBCAList = "CorporateMember/GetCompanyRegisteredWithBCAList?id={0}";
export const AcceptMembershipAmountInReconciliationOfSlotsMember = "MembershipPayment/AcceptMembershipAmountInReconciliationOfSlotsMember";
export const DeleteEventPaymentReconcilation = "MembershipPayment/DeleteEventPaymentReconcilation?id={0}";
export const GetAllDropDownForEntryManagement = "MembershipPayment/GetAllDropDownForEntryManagement?id={0}";
export const SendRenewalReminderMailForCorporateMember = "CorporateMember/SendRenewalReminderMailForCorporateMember?id={0}";

// Individual Member
export const GetIndividualAccountInformation = "IndividualMember/GetIndividualAccountInformation?id={0}";
export const GetAllDropDownsForIndividualMember = "IndividualMember/GetAllDropDownsForIndividualMember";
export const AddEditIndividualAccountInformation = "IndividualMember/AddEditIndividualAccountInformation";
export const AddEditEngagementTracking = "IndividualMember/AddEditEngagementTracking";
export const GetIndividualEngagementTrackingList = "IndividualMember/GetIndividualEngagementTrackingList?id={0}";
export const AddEditIndividualMembershipTaskAndSendPaymentLink = "MembershipTask/AddEditIndividualMembershipTaskAndSendPaymentLink";

export const GetIndividualMemberFiles = "IndividualMember/GetIndividualMemberFiles?id={0}&id2={1}";
export const GetIndividualFinancialInfo = "IndividualMember/GetIndividualFinancialInfo?id={0}";
export const GetIndividualFinanceReceiptInvoice = "IndividualMember/GetIndividualFinanceReceiptInvoice?id={0}&id2={1}&id3={2}";
export const GetIndividualBasicInformationById = "IndividualMember/GetIndividualBasicInformationById?id={0}";
export const GetIndividualMembershipSubscriptionById = "IndividualMember/GetIndividualMembershipSubscriptionById?id={0}";
export const AddEditIndividualMembershipSubscription = "IndividualMember/AddEditIndividualMembershipSubscription";
export const GetCONQUASMembershipSubscriptionById = "IndividualMember/GetCONQUASMembershipSubscriptionById?id={0}";

// SLOTS Member Services
export const GetSlotMemberList = "SlotsMember/GetAllSLOTSMembersList";
export const GetAllDropDownsForSLOTSMember = "SlotsMember/GetAllDropDownsForSLOTSMember";
export const GetSLOTSMemberDetailByMemberId = "SlotsMember/GetSLOTSMemberDetailByMemberId?id={0}";
export const SendPaymentLinkOfSLOTSMember = "SlotsMember/SendPaymentLinkOfSLOTSMember?id={0}";

export const SaveSlotMemberClarificationDetails = "SlotsMember/SaveSlotMemberClarificationDetails";
export const SendReconciliationUploadWorkshopProofLinkToSlots = "SlotsMember/SendReconciliationUploadWorkshopProofLinkToSlots";
export const GetSlotsMemberFiles = "SlotsMember/GetSlotsMemberFiles?id={0}&id2={1}&id3={2}";
export const EditSlotsMemberDetails = "SlotsMember/EditSlotsMemberDetails";
export const GetSLOTSMembershipSubscriptionById = "MembershipCorporate/GetSLOTSMembershipSubscriptionById?id={0}";
export const GetSlotsMemberCertificate = "SlotsMember/GetSlotsMemberCertificate?id={0}";
export const GetIndividualMemberTypeByID = "IndividualMember/GetIndividualMemberTypeByID?id={0}";
export const RemoveCompanyRegisteredBCAScopeById = "CorporateMember/RemoveCompanyRegisteredBCAScopeById?id={0}";
export const DownloadMultipleFilesZip = "Common/DownloadMultipleFilesZip";
export const RejectMemberApplication = "SlotsMember/RejectMemberApplication?id={0}";
export const ExpireSlotsMemberApplication = "SlotsMember/ExpireSlotsMemberApplication?id={0}";
export const GetMembershipPricingWithGst = "SlotsMember/GetMembershipSLOTSPricingWithGST";
export const AddSLOTSRegistrationHeadScopeTopUp = "SlotsMember/AddSLOTSRegistrationHeadScopeTopUp";
export const RemoveSLOTSRegistrationHeadScopeTopUp = "SlotsMember/RemoveSLOTSRegistrationHeadScopeTopUp?id={0}";
export const UpdateSLOTSRegistrationHeadScopeTopUp = "SlotsMember/UpdateSLOTSRegistrationHeadScopeTopUp";

export const AddEditAuthoriseRepresentativeSummary = "SlotsMember/AddEditAuthoriseRepresentativeSummary";
export const RemoveSLOTSAuthoriseRepresentativeSummary = "SlotsMember/RemoveSLOTSAuthoriseRepresentativeSummary";

export const BuyAdditionalRegistrationHeadTopUp = "SlotsMember/BuyAdditionalRegistrationHeadTopUp";
export const UpdateBasicAppicationDetails = "SlotsMember/UpdateBasicAppicationDetails";
export const UpdateOrganisationProfileDetails = "SlotsMember/UpdateOrganisationProfileDetails";

export const AcceptRenewSLOTSMembership = "SlotsMember/AcceptRenewSLOTSMembership?id={0}";

export const AddSLOTSRegistrationHeadScopeTopUpAdditional = "SlotsMember/AddSLOTSRegistrationHeadScopeTopUpAdditional";
export const RemoveSLOTSRegistrationHeadScopeTopUpAdditional = "SlotsMember/RemoveSLOTSRegistrationHeadScopeTopUpAdditional?id={0}";
export const SendRenewalReminderMailForSlotsMembers = "SlotsMember/SendRenewalReminderMailForSlotsMembers?id={0}";
export const DeleteSLOTSMember = "SlotsMember/DeleteSLOTSMember";
export const UpdateForceRenewalFlag = "SlotsMember/UpdateForceRenewalFlag?id={0}&id2={1}";
export const SetSLOTsMemberPricing = "SlotsMember/SetSLOTsMemberPricing";

// Batch Job Service
//export const AddMembershipBatchJobRecordsAfterPayment = "BatchJob/AddMembershipBatchJobRecordsAfterPayment?id={0}";

// AR Aging Reports
export const GetAllARAgingReportList = "BatchJob/GetAllARAgingReportList";
export const GenerateARAgingReport = "BatchJob/GenerateARAgingReport";
export const DownloadARAgingSheetByARAgingReportId = "BatchJob/DownloadARAgingSheetByARAgingReportId?id={0}";

// Credit Note 

export const GetCreditNoteInfoForCorporateMember = "Finance/GetCreditNoteInfoForCorporateMember?id={0}&id2={1}";
// export const GetCancellationReasonForCreditNote = "Finance/GetCancellationReasonForCreditNote";
// export const DownloadARAgingSheetByARAgingReportId = "BatchJob/DownloadARAgingSheetByARAgingReportId?id={0}";

export const GetAllWeeklyPaymentRecociliationRecords = "BatchJob/GetAllWeeklyPaymentRecociliationRecords"
export const GenerateWeeklyPaymentReconciliation = "BatchJob/GenerateWeeklyPaymentReconciliation?id={0}";
export const DownloadSheetByXeroPaymentReconciliationId = "BatchJob/DownloadSheetByXeroPaymentReconciliationId?id={0}";
export const GetCancellationReasonForCreditNote = "Finance/GetCancellationReasonForCreditNote";
export const AddFinanceCreditNote = "Finance/AddFinanceCreditNote";
export const GetAllFinanceCreditNoteList = "Finance/GetAllFinanceCreditNoteList";
export const GetFinanceCreditNoteStatusList = "Finance/GetFinanceCreditNoteStatusList";
export const GetFinanceCreditNoteById = "Finance/GetFinanceCreditNoteById?id={0}";
export const FinanceApproveRejectCreditNoteApproval = "Finance/FinanceApproveRejectCreditNoteApproval";
export const FinanceUpdateCreditNote = "Finance/FinanceUpdateCreditNote";
export const FinanceUpdateStatusForCreditNote = "Finance/FinanceUpdateStatusForCreditNote";
export const GetCreditNoteInfoForIndividualMember = "Finance/GetCreditNoteInfoForIndividualMember?id={0}&id2={1}";
export const AddMembershipCreditNoteApprovalRecordsForBatchJob = "BatchJob/AddMembershipCreditNoteApprovalRecordsForBatchJob?id={0}";
export const GetCreditNoteFiles = "Finance/GetCreditNoteFiles?id={0}&id2={1}&id3={2}";

// export const GetAllDropDownForEntryManagement = "IndividualMember/GetAllDropDownForEntryManagement";
export const GenerateExportMembershipData = "BatchJob/GenerateExportMembershipData";
export const DownloadSheetByExportMembershipDataId = "BatchJob/DownloadSheetByExportMembershipDataId?id={0}";
export const GetAllExportMembershipDataRecords = "BatchJob/GetAllExportMembershipDataRecords";

export const GenerateNewInvoiceAndReceipt = "IndividualMember/GeneratePDF";
// export const GetAllDropDownForEntryManagement = "IndividualMember/GetAllDropDownForEntryManagement";

//Report for Slots
export const GenerateallactiveSLOTsRecords = "SlotsMember/GenerateallactiveSLOTsRecords";
export const DownloadSheetForSLOTsRecords = "SlotsMember/DownloadSheetForSLOTsRecords?id={0}";
export const GetAllForSLOTsRecords = "SlotsMember/GetAllForSLOTsRecords";

export const SaveSLOTsDocuments = "SLOTSMember/SaveSLOTsDocuments";
export const DeleteSlotsMembersFiles = "SLOTSMember/DeleteSlotsMembersFiles";

// CONQUAS Member Services
export const GetCONQUASMemberList = "CONQUAS/GetAllCONQUASMembersList";
export const GetAllDropDownsForCONQUASMember = "CONQUAS/GetAllDropDownsForCONQUASMember";
export const EditCONQUASMemberDetails = "CONQUAS/EditCONQUASMemberDetails";
export const GetCONQUASMemberDetailByMemberId = "CONQUAS/GetCONQUASMemberDetailByMemberId?id={0}";
export const SentPaymentLinkOfCONQUASMember = "CONQUAS/SentPaymentLinkOfCONQUASMember?id={0}";
export const AcceptMembershipAmountInReconciliationOfCONQUASMember = "MembershipPayment/AcceptMembershipAmountInReconciliationOfCONQUASMember";
export const AcceptAndApprovedCONQUASApplication = "CONQUAS/AcceptAndApprovedCONQUASApplication";
export const SaveConquasMemberClarificationDetails = "CONQUAS/SaveConquasMemberClarificationDetails?id={0}";
export const RejectCONQUASMemberApplication = "CONQUAS/RejectCONQUASMemberApplication?id={0}";
export const UpdateCONQUASMemberDetails = "CONQUAS/UpdateCONQUASMemberDetails";
export const GetCONQUASMemberCertificate = "CONQUAS/GetCONQUASMemberCertificate?id={0}";
export const GetCONQUASMemberFiles = "CONQUAS/GetCONQUASMemberFiles?id={0}&id2={1}&id3={2}";
export const SaveCONQUASDocuments = "CONQUAS/SaveCONQUASDocuments";
export const AddEditConquasAcademicQualifications = "CONQUAS/AddEditConquasAcademicQualifications";
export const GetEducationInformationList = "CONQUAS/GetEducationInformationList?id={0}";
export const GetEducationInformationCONQUASTrainingList = "CONQUAS/GetEducationInformationCONQUASTrainingList?id={0}";
export const GetIndividualEducationInformationCONQUASGIPWorkshopList = "CONQUAS/GetIndividualEducationInformationCONQUASGIPWorkshopList?id={0}";
export const GetSupervisionExperienceList = "CONQUAS/GetSupervisionExperienceList?id={0}";
export const AddEditConquasTrainingWorkshop = "CONQUAS/AddEditConquasTrainingWorkshop";
export const AddEditConquasTrainingGIPWorkshop = "CONQUAS/AddEditConquasTrainingGIPWorkshop";
export const AddEditSupervisionExperience = "CONQUAS/AddEditSupervisionExperience";
export const SendPaymentLinkForCONQUASRenewalPayment = "CONQUAS/SendPaymentLinkForCONQUASRenewalPayment?id={0}";
export const UpdateCONQUAsLOADetails = "CONQUAS/UpdateCONQUAsLOADetails";
export const DeleteConquasAcademicQualifications = "CONQUAS/DeleteConquasAcademicQualifications";
export const DeleteConquasTrainingWorkshop = "CONQUAS/DeleteConquasTrainingWorkshop";
export const DeleteConquasTrainingGIPWorkshop = "CONQUAS/DeleteConquasTrainingGIPWorkshop";
export const DeleteSupervisionExperience = "CONQUAS/DeleteSupervisionExperience";
export const SendRenewalReminderMailForCONQUASMember = "CONQUAS/SendRenewalReminderMailForCONQUASMember?id={0}";
export const SendPaymentLinkForConquas = "CONQUAS/SendPaymentLinkForConquas?id={0}";
export const UpdateCONQUASPersonalParticularDetail = "CONQUAS/UpdateCONQUASPersonalParticularDetail";
 
//Slot Renewal Report
export const GetSlotRenewalRecordList = "SlotsMember/GetSlotRenewalRecordList";
export const DownloadSheetForSlotRenewalRecord = "SlotsMember/DownloadSheetForSlotRenewalRecord";

//Slot Newly Submittes Report
export const GetSlotNewlySubmittedRecordList = "SlotsMember/GetSlotNewlySubmittedRecordList";
export const DownloadSheetForSlotNewlySubmittedRecord = "SlotsMember/DownloadSheetForSlotNewlySubmittedRecord";

// For Deferred Income Reports
export const GetAllDeferredIncomeReportList = "Finance/GetAllDeferredIncomeReportList";
export const GenerateDeferredIncomeReport = "Finance/GenerateDeferredIncomeReport";
export const DownloadSheetByDeferredIncomeReportId = "Finance/DownloadSheetByDeferredIncomeReportId?id={0}";

//studentMember
export const GetTaskByStudentMemberId = "StudentMember/GetTaskByStudentMemberId?id={0}";
export const GetAllStudentMembersList = "StudentMember/GetAllStudentMembersList";
export const GetAllDropDownsForStudentMember = "StudentMember/GetAllDropDownsForStudentMember";
export const GetStudentMemberDetailByMemberId="StudentMember/GetStudentMemberDetailByMemberId?id={0}";
export const SaveStudentMemberClarificationDetails = "StudentMember/SaveStudentMemberClarificationDetails?id={0}";
export const UploadPdf = 'Common/UploadPDF';
export const EditStudentMemberDetails = "StudentMember/EditStudentMemberDetails";
export const AcceptMembershipAmountInReconciliationOfStudentMember = "MembershipPayment/AcceptMembershipAmountInReconciliationOfStudentMember";
export const SendPaymentLinkForCorporate = "MembershipTask/SendPaymentLinkForCorporate?id={0}";
export const SendPaymentLinkForIndividual = "MembershipTask/SendPaymentLinkForIndividual?id={0}";
export const SendPaymentLinkForSlot = "SlotsMember/SendPaymentLinkForSlot?id={0}";


export const GetOATIandslotsRecordList = "Common/GetOATIandslotsRecordList";
export const DownloadSheetForOATIandslotsRecordList = "Common/DownloadSheetForOATIandslotsRecordList";

