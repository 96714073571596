import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { decryptionAPI } from "../../utils/Encryption";
const API_URL = "http://localhost:5099/";
const UPLOAD_ENDPOINT = "api/Common/SaveFileToAmazon";
 
function TinyEceEditor({ handleChange, ...props }) {
  const custom_config = {
    extraPlugins: [uploadPlugin],
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "insertTable",
        "|",
        // 'imageUpload',
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("filename", file.filename);

            var config = { headers: { "content-type": "multipart/form-data" } };

            axios
              .post(`${API_URL}/${UPLOAD_ENDPOINT}`, body, config)
              .then((response) => {
                response = decryptionAPI(response.data);
                console.log(response);
                resolve({
                  default: `${API_URL}/${response.filePath}`,
                });
              })
              .catch((error) => {
                console.log(error.response);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div className="tiny-editor-sec" key={props && props.key ? `${props.key}div` : "TinyMCESection"}>
      <Editor
        value={props.data}
        key={props && props.key ? props.key : "TinyMCE"}
        // config={custom_config}
        // onReady={(editor) => {}}
        // onBlur={(event, editor) => {}}
        // onFocus={(event, editor) => {}}
        // onChange={(event, editor) => {
        //   handleChange(editor.getData());
        // }}
        onEditorChange={(newText, editor) => props.onChange(newText, editor)}
        {...props}
        init={{

          plugins: "link image code lists wordcount table",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist checklist outdent indent | wordcount | table ",
          statusbar: false,
          visual: false,
          // content_css: "index.css",
          content_style: '#tinymce{color:#000000;} table tr th{padding:5px;}',

        }}        
        
        apiKey="yk182e9hgiy5kr34ayz2isjukc5b0pn7spdoss1wyfju47h6"
      />
    </div>
  );
}

export default TinyEceEditor;
