import moment from "moment";
import React, { Component } from "react";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class BatchJobExtraction extends Component {
  constructor(props) {
    super(props);
    this.batchJobExtractionServices = new BatchJobExtractionServices();
    this.swalServices = new SwalServices();
    this.state = {
  
      
      weeklyBatchJobRecordList: [],
      weeklyGenerateDateLimit: '',
      monthlyBatchJobRecords: [],
      totalWeeklyRecordCount: 0,
      totalMonthlyRecordCount: 0,
      paginationWeekly: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      paginationMonthly: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      generateFromDateWeekly: '',
      generateFromDateMonthly: '',
      isLoading: false
    };
  }

  componentDidMount = () => {
    this.getAllWeeklyBatchJobRecords();
    this.getAllMonthlyBatchJobRecords();

  }
  
  
  getAllWeeklyBatchJobRecords = () => {
    this.setState({ isLoading: true });
    let paginationWeekly = { ...this.state.paginationWeekly };
    this.batchJobExtractionServices.getAllWeeklyBatchJobRecords(paginationWeekly).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
     
        var dateLimit = '';
        let data = details[0];
        if (data && data.endDate) {
          let sparateDate = data.endDate.split("T");
          dateLimit = sparateDate[0];
        }

        //convert string to date
        //var dattmp = dateLimit.split('-').reverse().join('-');
        var nwdate = new Date(dateLimit);

        // to add 1 day use:
        nwdate.setDate(nwdate.getDate() + 1);

        //to retrieve the new date use
        let newDate = [nwdate.getFullYear(), nwdate.getMonth() + 1, nwdate.getDate()].join('-');

        this.setState({
          weeklyBatchJobRecordList: details,
          totalWeeklyRecordCount: response.responseItem.responseContent.totalCount,
          weeklyGenerateDateLimit: newDate
        }, () => this.getAllMonthlyBatchJobRecords());
      }
      else {
        this.getAllMonthlyBatchJobRecords();
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  getAllMonthlyBatchJobRecords = () => {
    this.setState({ isLoading: true });
    let paginationMonthly = { ...this.state.paginationMonthly };
    this.batchJobExtractionServices.getAllMonthlyBatchJobRecords(paginationMonthly).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        this.setState({
          monthlyBatchJobRecords: details,
          totalMonthlyRecordCount: response.responseItem.responseContent.totalCount,
        });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  generateWeeklyBatchJob = () => {
    if (this.state.generateFromDateWeekly) {
      this.setState({ isLoading: true });
      this.batchJobExtractionServices.generateWeeklyBatchJob(this.state.generateFromDateWeekly).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //let details = response.responseItem.responseContent.itemList;
          this.swalServices.Success("Weekly batch job generated successfully.");
          this.getAllWeeklyBatchJobRecords();
        }
        else if (response.statusCode === 409) {
          this.swalServices.Error(response.message);
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
    else {
      this.swalServices.Alert('Please select date');
    }
  }

  generateMonthlyBatchJob = () => {
    if (this.state.generateFromDateMonthly) {
      this.setState({ isLoading: true });
      this.batchJobExtractionServices.generateMonthlyBatchJob(this.state.generateFromDateMonthly).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //let details = response.responseItem.responseContent.itemList;
          this.swalServices.Success("Monthly batch job generated successfully.");
          this.getAllMonthlyBatchJobRecords();
        }
        else if (response.statusCode === 409) {
          this.swalServices.Error(response.message);
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
    else {
      this.swalServices.Alert('Please select date');
    }
  }
  formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.toLocaleString('default', { year: '2-digit' });

    return `${day}${month}${year}`;
  }

  downloadSheetByBatchJobId = (xeroBatchJobId, startDate, endDate) => {
    this.setState({ isLoading: true });
    let request = [];

    request.push(xeroBatchJobId);
    request.push(startDate);
    request.push(endDate);
    const formattedStartDate = this.formatDate(startDate);
    const formattedEndDate = this.formatDate(endDate);
    this.batchJobExtractionServices.downloadSheetByBatchJobId(request).then((response) => {
      if (response.status === 200 && response.data != null) {
        // var filename = `${detail}${formattedDate}`;
        var blob = new Blob([response.data])
        saveAs(blob, `Business Transaction Report (${formattedStartDate}-${formattedEndDate}).xlsx`);
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'generateFromDateWeekly') {
      this.setState({ generateFromDateWeekly: value })
    }
    else if (name === 'generateFromDateMonthly') {
      this.setState({ generateFromDateMonthly: value })
    }
  }

  render() {
    return (
      <div className="main-body-section finance-body-sec batch-job-ectraction-main">
        {/* Business Transaction Batch Job Start */}

        <div className="bg-white">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Business Transaction Batch Job
              </h4>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-6 pb-12">
            <div className="col-span-3">

              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Generate from date
                <span className="text-[#c00000]">*</span>
              </h2>
              <input
                id="generateFromDateWeekly"
                name="generateFromDateWeekly"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={moment(this.state.generateFromDateWeekly).format("yyyy-MM-DD")}
                onChange={(event) => this.handleChange(event)}
              //min={this.state.weeklyGenerateDateLimit}
              //min='2023-11-10'
              />
            </div>
            <div className="col-span-4">
              <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.generateWeeklyBatchJob()}>
                Generate Last Week Batch Job
              </button>
            </div>

            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              {this.state.isLoading ? <PageLoader /> :
                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                        <div className="font-bold tble-th-txt text-left">
                          Generate Date
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                        <div className="font-bold tble-th-txt text-left">
                          Report ID
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">
                          Period Start
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">
                          Period End
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="scrollit text-sm custom-tbody scrolable">
                    {this.state.weeklyBatchJobRecordList.map((e) => {
                      return (
                        <tr className="custom-tbl-td flex items-center">
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[25%] ">
                            {moment(e.dateOfGeneration).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[15%]">
                            {e.batchNumber}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            {moment(e.startDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            {moment(e.endDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            <button
                              className="action-btn mr-4 flex items-center"
                              aria-controls="add-edit-modal"
                              onClick={() => {
                                this.downloadSheetByBatchJobId(e.xeroBatchJobId, e.startDate, e.endDate, 'Weekly');
                              }}
                            >
                              <p className="ml-2 tool-tip-txt underline text-[#005b9c]">
                                Download
                              </p>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr className="grid justify-items-center">
                    <td className="text-lg text-[#000000]	">No Records Found</td>
                  </tr> */}
                  </tbody>
                </table>
              }
            </div>

          </div>
        </div>
        {/* Business Transaction Batch Job End */}

        {/* Revenue Recognition Batch Job Start */}
        <div className="bg-white mt-10">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Revenue Recognition Batch Job
              </h4>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-6 pb-12">
            <div className="col-span-3">
              <label
                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                for="default"
              >
                Generate from date<span className="text-[#c00000]">*</span>
              </label>
              <input
                id="generateFromDateMonthly"
                name="generateFromDateMonthly"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={moment(this.state.generateFromDateMonthly).format("yyyy-MM-DD")}
                onChange={(event) => this.handleChange(event)}
              />
            </div>

            <div className="col-span-4">
              <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.generateMonthlyBatchJob()}>
                Generate Last Month Batch Job
              </button>
            </div>
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              {this.state.isLoading ? <PageLoader /> :
                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                        <div className="font-bold tble-th-txt text-left">
                          Generate Date
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                        <div className="font-bold tble-th-txt text-left">
                          Report ID
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">
                          Period Start
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">
                          Period End
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="scrollit text-sm custom-tbody scrolable">
                    {this.state.monthlyBatchJobRecords.map((e) => {
                      return (
                        <tr className="custom-tbl-td flex items-center">
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[25%] ">
                            {moment(e.dateOfGeneration).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[15%]">
                            {e.batchNumber}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            {moment(e.startDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            {moment(e.endDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            <button
                              className="action-btn mr-4 flex items-center"
                              aria-controls="add-edit-modal"
                              onClick={() => {
                                this.downloadSheetByBatchJobId(e.xeroBatchJobId, e.startDate, e.endDate, 'Monthly');
                              }}
                            >
                              <p className="ml-2 tool-tip-txt underline text-[#005b9c]">
                                Download
                              </p>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr className="grid justify-items-center">
              <td className="text-lg text-[#000000]	">No Records Found</td>
            </tr> */}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
        {/* Revenue Recognition Batch Job End */}
      </div>
    );
  }
}
